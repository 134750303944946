<template>  
  <v-container class="mx-0 px-0">    
    <v-sheet elevation="2" width="100%" class="pl-8 pr-8 pt-4 pb-4 ma-0" v-if="isDataAvailable()">
        <v-layout row wrap class="mx-0 mx-lg-16">
          <v-flex xs12 class="mt-8 mb-0 d-block">            
            <p class="caption text-center font-weight-light">ASK.GHAMIDI.ORG</p>
            <h4 class="text-center">Statement of Zakat</h4>
            <p class="overline text-center mb-0">{{ statementData.model.essentialInfo.firstName}} {{ statementData.model.essentialInfo.lastName}}</p>
            <p class="caption text-center mb-16">{{ formatDate(statementData.model.essentialInfo.beginDate) }} - {{ formatDate(statementData.model.essentialInfo.endDate) }}</p>
          </v-flex>

          <v-flex class="d-block" xs12>
            <p class="font-weight-bold">Zakat on Production / Earnings</p>
          </v-flex>

          
          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Agriculture<br>With Labor and Investment</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.income.agricultureEffortAndInvestment) }}</p>
            <p class="caption text--secondary">5% of {{ statementFormatCurrency(statementData.model.income.agricultureEffortAndInvestment) }}</p>
          </v-flex>

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Agriculture<br>With Labor Or Investment</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.income.agricultureEffortOrInvestment) }}</p>
            <p class="caption text--secondary">10% of {{ statementFormatCurrency(statementData.model.income.agricultureEffortOrInvestment) }}</p>
          </v-flex>

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Salaries /<br> Wages</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.income.salaries) }}</p>
            <p class="caption text--secondary">10% of {{ statementFormatCurrency(statementData.model.income.salaries) }}</p>
          </v-flex>

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Pensions</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.income.pensions) }}</p>
            <p class="caption text--secondary">10% of {{ statementFormatCurrency(statementData.model.income.pensions) }}</p>
          </v-flex>

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Capital Gains</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.income.capitalGains) }}</p>
            <p class="caption text--secondary">10% of {{ statementFormatCurrency(statementData.model.income.capitalGains) }}</p>
          </v-flex>              

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Business</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.income.businessIncome) }}</p>
            <p class="caption text--secondary">5% of {{ statementFormatCurrency(statementData.model.income.businessIncome) }}</p>
          </v-flex> 

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Rentals</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.income.rentalIncome) }}</p>
            <p class="caption text--secondary">10% of {{ statementFormatCurrency(statementData.model.income.rentalIncome) }}</p>
          </v-flex>              


          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Gifts of God</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.income.gifts) }}</p>
            <p class="caption text--secondary">20% of {{ statementFormatCurrency(statementData.model.income.gifts) }}</p>
          </v-flex>              

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2 font-weight-bold">Subtotal</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 font-weight-bold mb-0">{{ statementFormatCurrency(statementData.subtotals.income) }}</p>                    
          </v-flex>  

          <v-divider inset class="mb-4 ml-8"></v-divider>

          <v-flex class="d-block" xs12>
            <p class="font-weight-bold">Zakat on Assets</p>
          </v-flex>

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Cash</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.assets.cash) }}</p>
            <p class="caption text--secondary">2.5% of {{ statementFormatCurrency(statementData.model.assets.cash) }}</p>
          </v-flex>

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Precious Metals and Jewelery</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.assets.preciousMetals) }}</p>
            <p class="caption text--secondary">2.5% of {{ statementFormatCurrency(statementData.model.assets.preciousMetals) }}</p>
          </v-flex>              

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Investments</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.assets.investments) }}</p>
            <p class="caption text--secondary">2.5% of {{ statementFormatCurrency(statementData.model.assets.investments) }}</p>
          </v-flex>              

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Real Estate</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.assets.realEstate) }}</p>
            <p class="caption text--secondary">2.5% of {{ statementFormatCurrency(statementData.model.assets.realEstate) }}</p>
          </v-flex>    

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Business Inventory</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.assets.businessInventory) }}</p>
            <p class="caption text--secondary">2.5% of {{ statementFormatCurrency(statementData.model.assets.businessInventory) }}</p>
          </v-flex>              

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Livestock</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.livestock) }}</p>
            <p class="caption text--secondary">As per chart</p>
          </v-flex>              

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Other</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.assets.other) }}</p>
            <p class="caption text--secondary">2.5% of {{ statementFormatCurrency(statementData.model.assets.other) }}</p>
          </v-flex>              

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2 font-weight-bold">Subtotal</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 font-weight-bold mb-0">{{ statementFormatCurrency(statementData.subtotals.assets) }}</p>                    
          </v-flex>  

          <v-divider inset class="mb-4 ml-8"></v-divider>

          <v-flex class="d-block" xs12>
            <p class="font-weight-bold">Deductions</p>
          </v-flex>

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Zakat Paid</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.deductions.zakatPaid) }}</p>
          </v-flex>

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Taxes Paid</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.deductions.taxesPaid) }}</p>
          </v-flex>              

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2 font-weight-bold">Subtotal</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 font-weight-bold mb-0">{{ statementFormatCurrency(statementData.subtotals.deductions) }}</p>                    
          </v-flex>  

          <v-divider inset class="mb-4 ml-8"></v-divider>

          <v-flex class="d-block" xs12>
            <p class="font-weight-bold">Zakat Due</p>
          </v-flex>

          <v-flex class="d-block" xs12>
            <div class="ml-4 mb-4">
              <p class="body-2">Meet Nisab</p>                      
              <ul style="list-style-type: none">
                <li class="body-2">Production / Earnings - {{ (statementData.model.essentialInfo.isAboveProductionNisab) ? 'Yes' : 'No' }}</li>
                <li class="body-2">Assets - {{ (statementData.model.essentialInfo.isAboveAssetsNisab) ? 'Yes' : 'No' }}</li>
                <li class="body-2">Livestock - {{ (statementData.model.essentialInfo.isAboveLivestockNisab) ? 'Yes' : 'No' }}</li>
              </ul>
            </div>
          </v-flex>

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Total Due</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.totalDue) }}</p>
          </v-flex>

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2">Deferral - Real Estate</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 mb-0">{{ statementFormatCurrency(statementData.statement.deferrals.realEstate) }}</p>
          </v-flex>              

          <v-flex class="d-block" xs6>
            <div class="ml-4">
              <p class="body-2 font-weight-bold">Due Now</p>                      
            </div>
          </v-flex>
          <v-flex class="d-block text-right" xs6>
            <p class="body-2 font-weight-bold mb-0">{{ statementFormatCurrency(statementData.dueNow) }}</p>
          </v-flex>

        </v-layout>
    </v-sheet>
    <div v-if="!isDataAvailable()" class="text-center">
      <p>No data to print!</p>
      <v-btn
        color="primary"
        to="/tools/zakat-calculator"
        text>
        Go To Zakat Calculator
      </v-btn>      
    </div>
  </v-container>
</template>

<script>

  export default {
    name: 'Statement',
    data() {
      return {
        isPrintMode: false
      }
    },
    props: {
      statementData : Object
    },
    mounted: function () {
      window.addEventListener('pageshow', this.load);
    },
    methods: {
      load: function() {
        this.$nextTick(function () {
          var data = this.$route.query.prn;          

          if (data) {
            this.isPrintMode = true;
            this.statementData = JSON.parse(atob(data));

            setTimeout(function() {
              try {
                document.execCommand('print', false, null);
              }
              catch(e) {
                window.print();                
              }    
              finally {    
                window.close();
              }    
            }, 1000);
          }
        });  
      },
      isDataAvailable: function() {
        return (this.statementData != null && this.statementData !== undefined)
      },
      statementFormatCurrency: function(value) {
        return this.formatCurrency(value, this.statementData.model.essentialInfo.currency);
      },
      getQueryParam: function(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));      }      
    }    
  }
</script>

<template>
    <v-select
            v-model="select"
            :hint="`Your zakat will be calculated in ${select}`"
            :items="items"
            item-text="country"
            item-value="code"
            label="Select Currency"
            persistent-hint
            single-line
            @input="changeSelection()"/>   
</template>
<script>
  export default {
    name: 'CurrencySelector',
    data: function () {
      return {
        select: "USD",
        items: [
            {code:"USD", country:"USD - United States Dollars"},
            {code:"EUR", country:"EUR - Euro"},
            {code:"GBP", country:"GBP - United Kingdom Pounds"},
            {code:"DZD", country:"DZD - Algeria Dinars"},
            {code:"ARP", country:"ARP - Argentina Pesos"},
            {code:"AUD", country:"AUD - Australia Dollars"},
            {code:"ATS", country:"ATS - Austria Schillings"},
            {code:"BSD", country:"BSD - Bahamas Dollars"},
            {code:"BBD", country:"BBD - Barbados Dollars"},
            {code:"BEF", country:"BEF - Belgium Francs"},
            {code:"BMD", country:"BMD - Bermuda Dollars"},
            {code:"BRR", country:"BRR - Brazil Real"},
            {code:"BGL", country:"BGL - Bulgaria Lev"},
            {code:"CAD", country:"CAD - Canada Dollars"},
            {code:"CLP", country:"CLP - Chile Pesos"},
            {code:"CNY", country:"CNY - China Yuan Renmimbi"},
            {code:"CYP", country:"CYP - Cyprus Pounds"},
            {code:"CSK", country:"CSK - Czech Republic Koruna"},
            {code:"DKK", country:"DKK - Denmark Kroner"},
            {code:"NLG", country:"NLG - Dutch Guilders"},
            {code:"XCD", country:"XCD - Eastern Caribbean Dollars"},
            {code:"EGP", country:"EGP - Egypt Pounds"},
            {code:"FJD", country:"FJD - Fiji Dollars"},
            {code:"FIM", country:"FIM - Finland Markka"},
            {code:"FRF", country:"FRF - France Francs"},
            {code:"DEM", country:"DEM - Germany Deutsche Marks"},
            {code:"XAU", country:"XAU - Gold Ounces"},
            {code:"GRD", country:"GRD - Greece Drachmas"},
            {code:"HKD", country:"HKD - Hong Kong Dollars"},
            {code:"HUF", country:"HUF - Hungary Forint"},
            {code:"ISK", country:"ISK - Iceland Krona"},
            {code:"INR", country:"INR - India Rupees"},
            {code:"IDR", country:"IDR - Indonesia Rupiah"},
            {code:"IEP", country:"IEP - Ireland Punt"},
            {code:"ILS", country:"ILS - Israel New Shekels"},
            {code:"ITL", country:"ITL - Italy Lira"},
            {code:"JMD", country:"JMD - Jamaica Dollars"},
            {code:"JPY", country:"JPY - Japan Yen"},
            {code:"JOD", country:"JOD - Jordan Dinar"},
            {code:"KRW", country:"KRW - Korea (South) Won"},
            {code:"LBP", country:"LBP - Lebanon Pounds"},
            {code:"LUF", country:"LUF - Luxembourg Francs"},
            {code:"MYR", country:"MYR - Malaysia Ringgit"},
            {code:"MXP", country:"MXP - Mexico Pesos"},
            {code:"NLG", country:"NLG - Netherlands Guilders"},
            {code:"NZD", country:"NZD - New Zealand Dollars"},
            {code:"NOK", country:"NOK - Norway Kroner"},
            {code:"PKR", country:"PKR - Pakistan Rupees"},
            {code:"XPD", country:"XPD - Palladium Ounces"},
            {code:"PHP", country:"PHP - Philippines Pesos"},
            {code:"XPT", country:"XPT - Platinum Ounces"},
            {code:"PLZ", country:"PLZ - Poland Zloty"},
            {code:"PTE", country:"PTE - Portugal Escudo"},
            {code:"ROL", country:"ROL - Romania Leu"},
            {code:"RUR", country:"RUR - Russia Rubles"},
            {code:"SAR", country:"SAR - Saudi Arabia Riyal"},
            {code:"XAG", country:"XAG - Silver Ounces"},
            {code:"SGD", country:"SGD - Singapore Dollars"},
            {code:"SKK", country:"SKK - Slovakia Koruna"},
            {code:"ZAR", country:"ZAR - South Africa Rand"},
            {code:"KRW", country:"KRW - South Korea Won"},
            {code:"ESP", country:"ESP - Spain Pesetas"},
            {code:"XDR", country:"XDR - Special Drawing Right (IMF)"},
            {code:"SDD", country:"SDD - Sudan Dinar"},
            {code:"SEK", country:"SEK - Sweden Krona"},
            {code:"CHF", country:"CHF - Switzerland Francs"},
            {code:"TWD", country:"TWD - Taiwan Dollars"},
            {code:"THB", country:"THB - Thailand Baht"},
            {code:"TTD", country:"TTD - Trinidad and Tobago Dollars"},
            {code:"TRL", country:"TRL - Turkey Lira"},
            {code:"VEB", country:"VEB - Venezuela Bolivar"},
            {code:"ZMK", country:"ZMK - Zambia Kwacha"},
            {code:"EUR", country:"EUR - Euro"},
            {code:"XCD", country:"XCD - Eastern Caribbean Dollars"},
            {code:"XDR", country:"XDR - Special Drawing Right (IMF)"},
            {code:"XAG", country:"XAG - Silver Ounces"},
            {code:"XAU", country:"XAU - Gold Ounces"},
            {code:"XPD", country:"XPD - Palladium Ounces"},
            {code:"XPT", country:"XPT - Platinum Ounces"}
        ],
      }
    },
    methods: {
        changeSelection: function () {            
            this.$emit('change', this.select);
        }
    }    
  }
</script>
<template>
  <div class="zakat-calc">
    <Statement />
  </div>  
</template>

<script>
// @ is an alias to /src
import Statement from '@/components/zakat/Statement.vue'

export default {
  name: 'ZakatCalculatorPrint',
  components: {
    Statement
  }
}
</script>


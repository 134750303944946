<template>
  <v-container class="pa-0">
     <v-container>
      
      <div class="d-print-none text-h5 text-center">Zakat Calculator</div>
      <div class="d-print-none text-caption text-center">VERSION {{ model.version }}</div>
      
      <v-stepper id="wizard" v-model="currentStep" vertical class="elevation-0 mb-38">
        <v-stepper-step class="d-print-none"  step="1" v-bind:complete="currentStep > 1">
          About
        </v-stepper-step>
        <v-stepper-content class="d-print-none"  step="1">
          <v-container>
            <Intro />            
            <WizardButtons :currentStep="currentStep" :steps="totalSteps" @change="wizardStepChange"/>
          </v-container>
        </v-stepper-content>

        <v-stepper-step class="d-print-none"  step="2" v-bind:complete="currentStep > 2">
          Essential Information
        </v-stepper-step>
        <v-stepper-content  class="d-print-none"  step="2">
          <v-form ref="form"> 
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="model.essentialInfo.firstName"                  
                    :counter="30"
                    :rules="rules.notEmpty"
                    label="First name"
                    autocomplete="firstName"
                    required></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="model.essentialInfo.lastName"                  
                    :counter="30"
                    :rules="rules.notEmpty"
                    label="Last name"
                    autocomplete="lastName"
                    required></v-text-field>
                </v-col>

                <v-col cols="12">
                  <currency-selector
                    v-model="model.essentialInfo.currency" @change="currencySelected" />
                </v-col>

                <v-col cols="12" xs="12" md="6">
                  <v-menu
                    v-model="isBeginDateVisible"
                    :close-on-content-click="true"
                    :nudge-left="40"
                    transition="scale-transition"
                    offset-y
                    max-width="100%"
                    max-height="100%">                  
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="model.essentialInfo.beginDate"
                        label="Period start date"
                        hint="Zakat period start date"
                        prepend-icon="mdi-calendar"
                        :rules="rules.notEmpty"
                        readonly
                        v-bind="attrs"
                        v-on="on" />
                    </template>
                    <v-date-picker
                      v-model="model.essentialInfo.beginDate"
                      no-title                      
                      scrollable
                      class="dp"/> 
                  </v-menu>                 
                </v-col>

                <v-col cols="12" xs="12" md="6">
                  <v-menu
                    v-model="isEndDateVisible"
                    :close-on-content-click="true"
                    :nudge-left="40"
                    transition="scale-transition"
                    offset-y
                    max-width="100%"
                    max-height="100%">                  
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="model.essentialInfo.endDate"
                        label="Period end date"
                        hint="Zakat period end date"
                        prepend-icon="mdi-calendar"
                        :rules="rules.notEmpty"
                        readonly
                        v-bind="attrs"
                        v-on="on" />
                    </template>
                    <v-date-picker
                      v-model="model.essentialInfo.endDate"
                      no-title
                      scrollable
                      class="dp"/> 
                  </v-menu>                 
                </v-col>
              </v-row>
              <WizardButtons :currentStep="currentStep" :steps="totalSteps" @change="wizardStepChange"/>
            </v-container>
          </v-form>          
        </v-stepper-content>

        <v-stepper-step class="d-print-none"  step="3" v-bind:complete="currentStep > 3">
          Production / Earnings
        </v-stepper-step>
        <v-stepper-content class="d-print-none"  step="3">
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <div class="text-h5">Nisab</div>
                <div>Nisab for production / earnings is the minimum amount below which your state exempts tax or zakat on earnings.</div>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="model.essentialInfo.isAboveProductionNisab"
                  :label="(model.essentialInfo.isAboveProductionNisab) ? `My total production/earnings are greater than the nisab` : `My total production/earnings are less than the nisab`" />
              </v-col>
            </v-row>
            <v-row v-if="model.essentialInfo.isAboveProductionNisab">
              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.income.agricultureEffortAndInvestment"  
                  label="Agriculture Income With Both Labor and Investment"
                  hint="Total gross amount earned from agriculture with labor and financial investment excluding any amount exempt by your state." />
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.income.agricultureEffortOrInvestment"  
                  label="Agriculture Income With Either Labor Or Investment"
                  hint="Total gross amount earned from agriculture with either labor or financial investment excluding any amount exempt by your state." />
              </v-col>


              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.income.salaries"  
                  label="Salaries and Wages"
                  hint="Total gross amount earned with labor but without financial investment excluding any amount exempt by your state." />
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.income.pensions"  
                  label="Pensions"
                  hint="Total gross amount received in the form of pensions excluding any amount exempt by your state." />
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.income.capitalGains"  
                  label="Capital Gains"
                  hint="Total gross amount received with financial investment but without labor, excluding the investment principal"/>
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.income.businessIncome"  
                  label="Business Income"
                  hint="Total gross amount received with labor and financial investment excluding the investment principal and any associated costs. This should exclude any income received from retail businesses with inventory."/>
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.income.rentalIncome"  
                  label="Rental Income"
                  hint="Total rent received through property rentals excluding any expenses e.g. maintenance and mortgage."/>
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.income.gifts"  
                  label="Gifts of God"
                  hint="Total gross amount received without labor or financial investment e.g. unexpectedly found treasure or oil from the ground"/>
              </v-col>
            </v-row>
            <WizardButtons :currentStep="currentStep" :steps="totalSteps" @change="wizardStepChange"/>
          </v-container>
        </v-stepper-content>

        <v-stepper-step class="d-print-none"  step="4" v-bind:complete="currentStep > 4">
          Assets
        </v-stepper-step>
        <v-stepper-content class="d-print-none"  step="4">
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="text-h5">Nisab</div>
                <div>Nisab for assets is the minimum amount below which your state exempts tax or zakat on assets. If your state does not provide a nisab then you can use the standard nisab of <strong>642 grams of silver</strong> or equivalent value.</div>                
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="model.essentialInfo.isAboveAssetsNisab"
                  :label="(model.essentialInfo.isAboveAssetsNisab) ? `The total value of my assets is greater than the nisab` : `The total value of my assets is less than the nisab`" />
              </v-col>
            </v-row>
            <v-row v-if="model.essentialInfo.isAboveAssetsNisab">
              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.assets.cash"  
                  label="Cash"
                  hint="Total amount of cash you hold including in-hand, all personal and business bank accounts, as well as investment accounts"/>
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.assets.investments"  
                  label="Investments"
                  hint="Total current value of all investments (e.g. stocks and bonds) you hold"/>
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.assets.businessInventory"  
                  label="Business Inventory"
                  hint="Total current value of any business inventory"/>
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.assets.preciousMetals"  
                  label="Precious Metals and Jewelery"
                  hint="Total current value of precious metals and jewelery you own that is not in personal use"/>
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.assets.realEstate"  
                  label="Real Estate"
                  hint="Total current value of all real estate you own that is not in personal use and is not rented out"/>
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.assets.other"  
                  label="Other Assets"
                  hint="Total current value of all other valuables not in personal use"/>
              </v-col>
            </v-row>
            <WizardButtons :currentStep="currentStep" :steps="totalSteps" @change="wizardStepChange"/>
          </v-container>
        </v-stepper-content>

        <v-stepper-step class="d-print-none"  step="5" v-bind:complete="currentStep > 5">
          Livestock
        </v-stepper-step>
        <v-stepper-content class="d-print-none"  step="5">
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="text-h5">Nisab</div>
                <div>Nisab for livestock is provided by your state. In absense of any guidelines from your state you can use the standard nisab of <strong>5 camels, 30 cows, or 40 goats</strong>.</div>                
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="model.essentialInfo.isAboveLivestockNisab"
                  :label="(model.essentialInfo.isAboveLivestockNisab) ? `Yes I own livestock and meet the nisab` : `No I do not own livestock or meet the nisab`" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="model.essentialInfo.isAboveLivestockNisab">
                <v-row>
                  <v-col cols="12">
                    <v-expansion-panels class="mt-4 mb-2 px-0 px-md-16">
                      <v-expansion-panel>
                        <v-expansion-panel-header class="text-h5">Livestock Chart</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <strong>Camels</strong>
                          <ul>
                            <li><u>Count 5-24:</u> 1 she-goat for every 5 camels</li>
                            <li><u>Count 25-35:</u> 1 one year old she-camel; in absence 1 two year old camel</li>
                            <li><u>Count 36-45:</u> 1 two year old she-camel</li>
                            <li><u>Count 46-60:</u> 1 three year old she-camel</li>
                            <li><u>Count 61-75:</u> 1 four year old she-camel</li>
                            <li><u>Count 76-90:</u> 2 two year old she-camels</li>
                            <li><u>Count 91-120:</u> 2 three year old she-camels</li>
                            <li><u>Count over 120:</u> 1 two year old she-camel on every 40 camels and 1 three year old she-camel on every 50 camels</li>
                          </ul>
                          
                          <strong>Cows</strong>
                          <ul>
                            <li>1 one year old calf on every 30 cows and 1 two year old calf on every 40 cows</li>
                          </ul>
                          
                        <strong>Goats</strong>
                          <ul>
                            <li><u>Count 40-120:</u> 1 she-goat</li>
                            <li><u>Count 121-200:</u> 2 she-goats</li>
                            <li><u>Count 201-300:</u> 3 she-goats</li>
                            <li><u>Count over 300:</u> 1 she-goat for every 100 goats</li>
                          </ul>                        
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <div class="text-body-2 font-weight-light text-left" style="width:inherit">Use this chart to calculate your zakat on livestock</div>
                    </v-expansion-panels>
                                  
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">                
                    <v-currency-field
                      type="number"
                      :prefix="model.essentialInfo.currencySymbol"
                      v-model="model.livestock"  
                      label="Livestock"
                      hint="Total current market value of your livestock as per the chart above"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <WizardButtons :currentStep="currentStep" :steps="totalSteps" @change="wizardStepChange"/>
          </v-container>
        </v-stepper-content>        

        <v-stepper-step class="d-print-none"  step="6" v-bind:complete="currentStep > 6">
          Credits and Debits
        </v-stepper-step>
        <v-stepper-content class="d-print-none"  step="6">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.deductions.taxesPaid"  
                  label="Taxes Paid"
                  hint="Total amount of direct and indirect taxes you have paid for this period including state and federal income tax, property tax, sales tax, and duties"/>
              </v-col>

              <v-col cols="12">
                <v-currency-field
                  type="number"
                  :prefix="model.essentialInfo.currencySymbol"
                  v-model="model.deductions.zakatPaid"  
                  label="Zakat Already Paid"
                  hint="Total amount of zakat you have already paid for this period"/>
              </v-col>
            </v-row>
            <v-row v-if="statement.assets.realEstate > 0">
              <v-col cols="12">
                <v-card class="mx-auto" elevation="0">
                    <v-card-title>Partial Deferrals</v-card-title>
                    <v-card-text>
                      <p>If you can't afford to pay the total amount of your zakat at this time, you may defer the payment on certain assets as listed below.</p>
                      <p>If you choose to defer, you must calculate and accrue the zakat on those assets every year until you are able to pay the accrued amount, or you have sold those assets.</p>
                      <v-divider />
                    </v-card-text>
                    <v-card-actions class="flex-column align-start">
                      <div class="text-caption">Defer zakat on real estate assets?</div>
                      <div>
                        <v-switch inset :disabled="!(statement.assets.realEstate > 0)"
                          v-model="model.deferrals.realEstate"
                          :label="(model.deferrals.realEstate) ? `Defer ` + formatCurrency(statement.assets.realEstate, model.essentialInfo.currency) + ` zakat on my real estate assets` : `Pay full zakat due on my real estate assets`" />
                      </div>

                    </v-card-actions>
                  </v-card>
              </v-col>
            </v-row>
            <WizardButtons :currentStep="currentStep" :steps="totalSteps" @change="wizardStepChange"/>
          </v-container>
        </v-stepper-content>

        <v-stepper-step class="d-print-none"  step="7" v-bind:complete="currentStep > 7">
          Statement
        </v-stepper-step>
        <v-stepper-content  step="7" class="mx-0 px-0 mx-lg-16 px-lg-16">
          <v-container id="zakat-statement" class="mx-0 px-0">
            <Statement :statementData="getStatementData()"  />
            <WizardButtons :currentStep="currentStep" :steps="totalSteps" @change="wizardStepChange"/>
          </v-container>
        </v-stepper-content>

        <v-stepper-step class="d-print-none"  step="8" v-bind:complete="currentStep > 8">
          Disbursement & Print
        </v-stepper-step>
        <v-stepper-content class="d-print-none"  step="8">
          <v-container>
            <div class="text-h5">Zakat Eligibility</div>
            <div>
               <p>Following entities are eligible for your zakat in the order of precedence:</p>
               <ol>
                  <li class="mb-2"><span class="font-weight-medium">State</span><br/>Obligatory zakat is the right of state. The taxes you pay to your state are in lieu of your obligatory zakat.</li>
                  <li class="mb-2"><span class="font-weight-medium">Kith and Kin</span><br/>If your have paid your zakat to your state but still have an amount leftover, you should look for any needy persons in your family and/or relatives.</li>
                  <li class="mb-2"><span class="font-weight-medium">Other Individuals / Organizations</span><br/>If you still have leftover zakat, you can pay it to any needy persons or charitable organizations that will utilize it for the benefit of the society. The <b>Ghamidi Center of Islamic Learning</b> is a zakat eligible organization that is helping numerous of people around the world in understanding the message of God. <b>We strongly encourage you to consider us for your zakat as well as other donations.</b></li>
               </ol>
              <p class="mt-4 text-center">
                <v-btn color="secondary" :href="getPaymentUrl()" target="_blank" ag_target="_blank">Pay Zakat</v-btn>                
              </p>
            </div>
            <div class="text-h5">Print Statement</div>
            <div class="mt-2">
               <p>In order to protect your privacy, we do not persist any identifiable financial information you have entered in this zakat calculator. 
                 To keep a record for your personal use, please take a moment to print your statement.</p>
              <p class="mt-4 text-center">
                <v-btn color="primary" @click="printStatement($event)">Print</v-btn>
              </p>
            </div>
            <v-divider class="mt-2" />
            <WizardButtons :currentStep="currentStep" :steps="totalSteps" @change="wizardStepChange"/>
          </v-container>
        </v-stepper-content>
      </v-stepper>       
    </v-container>
       
    <v-footer id="zakat-calculator-footer" padless fixed elevate-on-scroll class="d-print-none indigo lighten-5 pb-5" :class="this.$globals.IsApp ? 'show-margin' : 'no-show-margin'">
      <v-progress-linear class="pa-0 mb-4" :value="(currentStep / totalSteps) * 100"></v-progress-linear>
      <v-toolbar-title id="header-total" class="rounded-lg mx-auto pa-2 text-center accent text-no-wrap white--text">
        Due Now: {{ formatCurrency(getZakatTotal(), model.essentialInfo.currency) }}
      </v-toolbar-title>
    </v-footer>      
    
  </v-container>
</template>				


<script>

import Intro from './Intro';
import CurrencySelector from './CurrencySelector';
import WizardButtons from './WizardButtons';
import Statement from './Statement';

export default {
  name: "Calculator",   
    components: {Intro, CurrencySelector, WizardButtons, Statement},
    data() {
      return {
        isBeginDateVisible: false,
        isEndDateVisible: false,
        currentStep: 1,
        totalSteps: 8,
        rules: {
          notEmpty: [val => (val || '').length > 0 || 'This field is required'],
          // email: [
          //   val => (val || '').length > 0 || 'This field is required',
          //   v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
          // ]
        },
        model: {
          version: this.getAppVersion(),            
          essentialInfo: {
            firstName: this.getFirstName(),
            lastName: this.getLastName(),
            email: null,
            currency: 'USD',
            currencySymbol: '$',
            beginDate: this.getNewBeginDate(),
            endDate: this.getNewEndDate(),
            isAboveAssetsNisab: true,
            isAboveProductionNisab: true,
            isAboveLivestockNisab: false
          }, 
          income: {
            agricultureEffortAndInvestment: null,
            agricultureEffortOrInvestment: null,
            salaries: null,
            pensions: null,
            businessIncome: null,
            rentalIncome: null,
            capitalGains: null,							
            gifts: null,
            subtotal: 0
          },
          assets: {
            cash: null,
            preciousMetals: null,
            investments: null,
            realEstate: null,
            businessInventory: null,
            other: null,            
            subtotal: 0
          },
          livestock: null,
          deductions: {
            taxesPaid: null,
            zakatPaid: null,
            subtotal: 0
          },  
          deferrals: {
            realEstate: false
          },
          due: 0	
        }
      }        
    },
    computed: {
      statement: function() {              
        return {
          income: {
            businessIncome: Number(this.model.income.businessIncome) * 0.05,
            rentalIncome: Number(this.model.income.rentalIncome) * 0.1,
            agricultureEffortAndInvestment: Number(this.model.income.agricultureEffortAndInvestment) * 0.05,
            agricultureEffortOrInvestment: Number(this.model.income.agricultureEffortOrInvestment) * 0.1,
            salaries: Number(this.model.income.salaries) * 0.1,
            pensions: Number(this.model.income.pensions) * 0.1,
            capitalGains: Number(this.model.income.capitalGains) * 0.1,	                  
            gifts: Number(this.model.income.gifts) * 0.2                  
          },
          assets: {
            cash: Number(this.model.assets.cash) * 0.025,
            preciousMetals: Number(this.model.assets.preciousMetals) * 0.025,
            investments: Number(this.model.assets.investments) * 0.025,
            realEstate: Number(this.model.assets.realEstate) * 0.025,
            businessInventory: Number(this.model.assets.businessInventory) * 0.025,
            other: Number(this.model.assets.other) * 0.025,            
          },
          livestock: 
            Number(this.model.livestock),
          deductions: {
            taxesPaid: -Number(this.model.deductions.taxesPaid),
            zakatPaid: -Number(this.model.deductions.zakatPaid)
          },
          deferrals: {
            realEstate: (this.model.deferrals.realEstate) ? -Number(this.model.assets.realEstate) * 0.025 : 0
          }
        }
      }
    },    
    methods: {
      getAppVersion() {
        var version = '1.0';
        var container = document.getElementById("vue-app-container");
        
        if (container != null && container != 'undefined')
          version = container.getAttribute("data-app-version");
        
        return version;
      },
      getFirstName() {
        return (window.zlbpos_appUser) ? window.zlbpos_appUser.firstName : null;
      },
      getLastName() {
        return (window.zlbpos_appUser) ? window.zlbpos_appUser.lastName : null;
      },
      printStatement(event) {
        event.preventDefault();
        var printData = JSON.stringify(this.getStatementData());        
        window.open('/vue-app-print-proxy/?ag_target=_blank&prn=' + btoa(printData), '_blank');
      },
      getPaymentUrl() {
        return encodeURI('https://www.ghamidi.org/pay-zakat/?utm_source=ask-ghamidi&utm_medium=app&utm_campaign=zakat&amount=' 
        + Number(this.getZakatTotal()).toFixed(2) 
        + '&first=' + this.model.essentialInfo.firstName
        + '&last=' + this.model.essentialInfo.lastName);
      },
      wizardStepChange(step) {
        var isValid = (this.currentStep == 2) ? this.$refs.form.validate() : true;

        if (isValid)
          this.currentStep = step;
      },
      getNewBeginDate() {
        var date = new Date();
        date.setFullYear(date.getFullYear() - 1);
        return date.toISOString().split('T')[0];
      },
      getNewEndDate() {
        var date = new Date();
        date.setDate(date.getDate() - 1);
        return date.toISOString().split('T')[0];
      },
      getZakatSubTotals() {        
        return {
          income:
            this.statement.income.businessIncome 
            + this.statement.income.rentalIncome
            + this.statement.income.agricultureEffortAndInvestment
            + this.statement.income.agricultureEffortOrInvestment
            + this.statement.income.salaries 
            + this.statement.income.pensions
            + this.statement.income.capitalGains 
            + this.statement.income.gifts,
          assets:
            this.statement.assets.cash
            + this.statement.assets.preciousMetals
            + this.statement.assets.investments
            + this.statement.assets.realEstate
            + this.statement.assets.businessInventory
            + this.statement.assets.other,
          livestock: 
            this.statement.livestock,
          deductions: 
            this.statement.deductions.taxesPaid
            + this.statement.deductions.zakatPaid,
          deferrals:
            this.statement.deferrals.realEstate
        }
      },
      getZakatTotal(excludedeferrals = false) {
        var subTotals = this.getZakatSubTotals();

        var total = 
          ((this.model.essentialInfo.isAboveProductionNisab) ? subTotals.income : 0) + 
          ((this.model.essentialInfo.isAboveAssetsNisab) ? subTotals.assets : 0) + 
          ((this.model.essentialInfo.isAboveLivestockNisab) ? subTotals.livestock : 0) +
          subTotals.deductions + 
          ((excludedeferrals) ? 0 : subTotals.deferrals);
        
        return (total > 0) ? total : 0;        
      },
      currencySelected: function (selected) {
        this.model.essentialInfo.currency = selected;

        var formatter = new Intl.NumberFormat(this.getBrowserLocale(), {
          style: 'currency',
          currency: selected
        });

        var parts = formatter.formatToParts(1).filter(x => x.type == 'currency');
        this.model.essentialInfo.currencySymbol = (parts.length > 0) ? parts[0].value : "$";
      },
      getStatementData: function() {
        return {
              model: this.model,
              statement: this.statement,
              subtotals: this.getZakatSubTotals(),
              dueNow: this.getZakatTotal(),
              totalDue: this.getZakatTotal(true)
        }
      },
    },
};
</script> 

<style scoped>
  #header-total {font-size: 0.95rem;}
  .v-input >>> .v-text-field__prefix {
      font-size: small;
      font-weight: 300;
  }
  #zakat-calculator-footer.show-margin { margin-bottom: 75px;}  
  #wizard.mb-38 { margin-bottom: 152px; }
  .ls1 { letter-spacing: 6px ;}

  /* Datepicker conflicting CSS in AG theme */
  .dp >>> .v-date-picker-table--date td {
      padding: 0;
      border-bottom: none;
  }  

  #livestock-info ul,
  ul#livestock-nisab{
    list-style-type: none;
  }
</style>


<template>  
  <v-container class="d-print-none">    
    <div class="d-block float-right mt-2 mr-1">
      <v-btn text @click="prevStep" v-if="!isFirstStep()"> Back </v-btn>
      <v-btn color="primary" @click="nextStep" v-if="!isLastStep()">
        Continue
      </v-btn>
      <v-btn color="primary" @click="firstStep" v-if="isLastStep()">
        Restart
      </v-btn>
    </div>         
  </v-container>
</template>

<script>
  export default {
    name: 'WizardButtons',
    props: [
      'currentStep',
      'steps'
    ],
    methods: {
      isFirstStep() {
        return Number(this.currentStep) == 1;
      },
      isLastStep() {
        return Number(this.currentStep) == Number(this.steps);
      },
      firstStep() {
        this.$emit('change', 1);
      },
      nextStep() {
        this.$emit('change', Number(this.currentStep) + 1);
      },
      prevStep() {
        this.$emit('change', Number(this.currentStep) - 1);
      }
    }    
  }
</script>

<template>  
  <v-container>
    <div class="text-h5">Before You Begin</div>
    <div>
      <p>This Zakat Calculator helps you calculate your obligatory zakat according to the Islamic principles as have been explained by Javed Ahmed Ghamidi. Before you start, please take a moment to familiarize yourself with the following summary of the law of zakat.</p>
      <p>Obligatory zakat consists of the following components:</p>
        <p><span class="font-weight-medium">1. Zakat on Earnings (Production)</span><br/>
        The rules for zakat on earnings / productions are as follows:</p>
        <div class="ml-2">
        <div class="text-overline">Agriculture</div>
        <ul>
          <li>5% on amount earned from both labor as well as financial investments</li> 
          <li>10% on amount earned from either labor or financial investments</li>
        </ul>
        <div class="text-overline">Other Earnings</div>
        <ul>                 
          <li>5% on amount earned from both labor and financial investments (e.g. profit from manufacturing or service businesses)</li>
          <li>10% on amount earned from either labor or financial investments (e.g. salary or capital gains)</li>                  
          <li>20% on amount earned without labor or financial investments (gifts from God e.g. found treasures)</li>                  
        </ul>
        </div>
        <p class="mt-4">
          <span class="font-weight-medium">2. Zakat on Assets</span><br/>
           Zakat on assets is due once every year. Your state may determine a day of the year for your zakat to be due. If not, you can pick a day of the year and pay your zakat on the same day every year. This zakat is calculated as 2.5% of the sum total of all of your cash as well as the current value of all other assets in your posession on the day when your zakat becomes due. 
        </p>
        <p>
          In addition to any exemptions your state may grant you, the following items are always exempt from your zakat:
          <ul>
            <li>Items of personal use (e.g. your car, house, and personal use jewellery)</li>
            <li>Means of production (e.g. farm lands, factories, tools, and equipment)</li>
          </ul>
        </p>
        <p class="mt-4">
          <span class="font-weight-medium">3. Zakat on Livestock</span><br/>
          Zakat on livestock is due on camels, cows, and goats used to produce labor and commodities such as meat, eggs, milk, fur, leather, and wool. This zakat is calculated according to the chart provided later in this calculator.
        </p>
        <p>
          <v-btn color="primary" text outlined small @click="goToFaq">
            More Details and FAQ
          </v-btn>
        </p>
        <div class="privacy-highlight2 mt-8">
          <p><b>Privacy</b></p>
          <p>Your privacy is really important to us. Please be assured that <u>this calculator does not store any personally identifiable financial information</u>.</p>
        </div>
    </div>
    <v-divider class="mt-2" />
  </v-container>
</template>

<script>
  export default {
    name: 'Intro',
    methods: {
      goToFaq: function() {
        window.location.href = 'https://ask.ghamidi.org/forums/discussion/46733/';
      }
    }
  }
</script>


<template>
  <v-container>
    <v-spacer />
    <v-card class="mx-auto" max-width="400" to="/tools/zakat-calculator"
    >
      <v-img
        class="white--text align-end"
        height="200px"
        src="../assets/zakat-calculator-blank-400x200.png">      
        <v-card-title>Zakat Calculator</v-card-title>
      </v-img>

      <!-- <v-card-subtitle class="pb-0">
        Number 10
      </v-card-subtitle> -->

      <v-card-text class="text--primary">
        

        <div>Zakat Calculator allows you to calculate your obligatory zakat according to the Islamic principles as have been explained by Javed Ahmed Ghamidi</div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          to="/tools/zakat-calculator"
          text>
          Go There
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Tools',
}
</script>

<template>
  <div class="zakat-calc">
    <Calculator />
  </div>  
</template>

<script>
// @ is an alias to /src
import Calculator from '@/components/zakat/Calculator.vue'

export default {
  name: 'ZakatCalculator',
  components: {
    Calculator
  }
}
</script>
